import React from 'react';
import { Box } from '@mui/material';
import JotformEmbed from 'react-jotform-embed';

const Contact = () => {
    return (
        <Box p={5} component="form" noValidate autoComplete="off">
            <JotformEmbed src="https://form.jotform.com/240363958366163" />
        </Box>
    );
};

export default Contact;

import React from 'react';
import { Box, Typography } from '@mui/material';
import ScheduleButton from '../ScheduleButton';

const GetStarted = () => {
    return (
        <Box
            py={'3rem'}
            textAlign={'center'}
            alignItems={'center'}
            sx={{
                backgroundColor: '#f2ece0',
                backgroundImage: 'url(/images/bgdots.png)',
            }}
        >
            <Typography variant="h4" fontFamily={"'Raleway',sans-serif;"}>
                Let's Get Started
            </Typography>
            <Box
                display={'flex'}
                textAlign={'left'}
                justifyContent={'center'}
            >
                <Typography variant="body1" py={5} px={2} maxWidth={'1000px'}>
                    At this point, you can follow one of two paths... 
                    <br />
                    <br />
                    First, you
                    can move on without clicking the button below and continue
                    doing what you've always done. And, chances are, you'll
                    continue getting what you've always gotten.
                    <br />
                    <br />
                    Or you can choose the second path...
                    <br />
                    <br />
                    You can choose to do something different, to stop trying to
                    figure this out alone. You know where you'll be in a year if 
                    you choose to do nothing, but there's no telling how far 
                    you'll go with the right tools and support.
                    <br />
                    <br />
                    If you're ready to build the life you want, I invite you to 
                    choose the second path, click the button below, and take the 
                    first step toward a bigger, brighter future.
                </Typography>
            </Box>
            <ScheduleButton />
        </Box>
    );
};

export default GetStarted;

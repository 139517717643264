import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material';
import TopIntro from './Services/TopIntro';
import OfferedServices from './Services/OfferedServices';
import Overwhelmed from './Services/Overwhelmed';
import GetStarted from './Services/GetStarted';
import Imagine from './Services/Imagine';
import FreeAssessment from './FreeAssessment';


const Services = () => {
    const theme = useTheme();
    theme.typography.h2 = {
        fontSize: '3rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <TopIntro />
            <Overwhelmed />
            <OfferedServices />
            <Imagine />
            <GetStarted />
            <FreeAssessment />
        </ThemeProvider>
    );
};

export default Services;

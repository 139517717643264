import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import SentimentVerySatisfied from '@mui/icons-material/SentimentVerySatisfied';

const BusinessImagine = () => {
    const details = [
        ['Team', "Build an amazing team and culture"],
        ['Clarity', "Create clarity around roles and desired outcomes"],
        ['Accountability', "Hold your team members accountable with grace and dignity"],
    ];

    return (
        <Box pt={'4rem'} textAlign={'center'}>
            <Typography variant="h4">Imagine For A Moment...</Typography>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography
                    variant="body1"
                    maxWidth={'1024px'}
                    p={3}
                    textAlign={'left'}
                >
                    Imagine being excited to show up for work on 
                    Monday morning. You're excited because you have an 
                    amazing team to work with and together you're crushing
                    your goals. Imagine going home feeling exhausted, not 
                    from frustration, but from working really hard to 
                    accomplish things that truly matter. Sounds far
                    fetched, I know, but it's possible when you have the 
                    right tools and support to be the leader your team 
                    needs.
                </Typography>
            </Box>

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
                py={'3rem'}
                px={1}
            >
                {details.map((d, index) => (
                    <Grid key={index} item xs={12} md={4}>
                        <SentimentVerySatisfied
                            sx={{ fontSize: '7rem', color: '#c5a774' }}
                        />
                        <Typography variant="h5" textTransform={'uppercase'}>
                            {d[0]}
                        </Typography>
                        <Typography variant="h6">{d[1]}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default BusinessImagine;

import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import SentimentVerySatisfied from '@mui/icons-material/SentimentVerySatisfied';

const Imagine = () => {
    const details = [
        ['Vision', 'Create a vision so you know what you want'],
        ['Action Plan', 'Make an action plan so you know how to get it'],
        [
            'Mindset',
            'Build a winning mindset so you can move forward with confidence',
        ],
    ];

    return (
        <Box pt={'4rem'} textAlign={'center'}>
            <Typography variant="h4">Imagine For A Moment...</Typography>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography
                    variant="body1"
                    maxWidth={'1024px'}
                    p={3}
                    textAlign={'left'}
                >
                    Imagine waking up with with a clear vision of what you want
                    armed with a solid plan and the confidence to get it.
                    Imagine, just for a moment, loving your life and bursting
                    with excitement to start your day. Sounds far fetched, I
                    know, but it's possible when you have a plan and the right
                    tools and support to build the life you want.
                </Typography>
            </Box>

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
                py={'3rem'}
                px={1}
            >
                {details.map((d, index) => (
                    <Grid key={index} item xs={12} md={4}>
                        <SentimentVerySatisfied
                            sx={{ fontSize: '7rem', color: '#c5a774' }}
                        />
                        <Typography variant="h5" textTransform={'uppercase'}>
                            {d[0]}
                        </Typography>
                        <Typography variant="h6">{d[1]}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Imagine;

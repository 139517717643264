import React, { useState } from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import { PopupModal } from 'react-calendly';
import { EventCategory, sendEvent } from '../utils/Analytics';

interface ScheduleButtonProps {
    className?: string | undefined;
    sx?: SxProps<Theme> | undefined;
}

const defaultSx = {
    backgroundImage:
        'radial-gradient(at center center, #475B70 0%, #253342 100%)',
};

const ScheduleButton = ({ sx, className }: ScheduleButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const finalStyles = sx ? { ...defaultSx, ...sx } : defaultSx;

    return (
        <span className="App">
            <Button
                variant="contained"
                color="success"
                onClick={() => {
                    setIsOpen(true);
                    sendEvent(
                        EventCategory.BookACallBtn,
                        'click',
                        'Book a call button clicked'
                    );
                }}
                sx={finalStyles}
                className={className}
            >
                Book a call
            </Button>
            <PopupModal
                url="https://calendly.com/renewed-mind-coaching/discovery"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById('root') as HTMLElement}
            />
        </span>
    );
};

export default ScheduleButton;

import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { HandshakeOutlined } from '@mui/icons-material';
import ScheduleButton from '../ScheduleButton';

const OfferedBusinessServices = () => {
    return (
        <Box
            py={'3rem'}
            textAlign={'center'}
            sx={{
                backgroundColor: '#f2ece0',
                backgroundImage: 'url(/images/bgdots.png)',
            }}
        >
            <Grid
                container
                spacing={4}
                py={'3rem'}
                px={2}
                display={'flex'}
                justifyContent={'center'}
            >
                <Grid item xs={12} md={4} textAlign={'center'}>
                    <HandshakeOutlined
                        sx={{ fontSize: '7rem', color: '#c5a774' }}
                    />
                    <Typography variant="h6">One-On-One and Group Coaching</Typography>
                    <Typography variant="body1" py={1} px={2} textAlign={'left'} maxWidth={'1024px'}>
                        In my Leadership Bootcamp Program, I will walk you through 
                        the everything you need as a leader to build an amazing team, 
                        create clarity around roles and desired outcomes, and hold your 
                        team members accountable with grace and dignity in a series of 
                        weekly 1-hour sessions.
                    </Typography>
                    <Typography variant="h6">Workshops</Typography>
                    <Typography variant="body1" py={1} px={2} textAlign={'left'} maxWidth={'1024px'}>
                        If you're doing well in some areas, but need help 
                        building skills in a specific area, workshops are a good
                        format to consider. Let me know where you're struggling 
                        and I'll put together a custom workshop to address your 
                        needs.
                        
                    </Typography>
                </Grid>
            </Grid>
            <ScheduleButton />
        </Box> 
    );    
};
export default OfferedBusinessServices;

import React from 'react';
import { Box, Typography, Link, useTheme, ThemeProvider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    const theme = useTheme();
    const iconFontSize = 40;
    const links = [
        {
            href: 'https://www.linkedin.com/in/nick-schader-a21b18a/',
            icon: <LinkedInIcon sx={{ fontSize: iconFontSize }} />,
        },
        {
            href: 'https://www.facebook.com/nick.schader.54/',
            icon: <FacebookIcon sx={{ fontSize: iconFontSize }} />,
        },
        {
            href: 'https://www.instagram.com/renewedmindcoach/',
            icon: <InstagramIcon sx={{ fontSize: iconFontSize }} />,
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Box textAlign="center" p={4}>
                <Typography variant="body1" color="textSecondary">
                    Follow us on
                    <br />
                    {links.map((link, index) => (
                        <Link
                            key={index}
                            href={link.href}
                            target="_blank"
                            px={1}
                            color="textSecondary"
                        >
                            {link.icon}
                        </Link>
                    ))}
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default Footer;

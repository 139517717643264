import React from 'react';
import { Box, Typography } from '@mui/material';

const Overwhelmed = () => {
    return (
        <Box pt={'4rem'} textAlign={'center'} alignItems={'center'}>
            <Typography variant="h4" fontFamily={"'Raleway',sans-serif;"}>
                Overwhelmed & Out of Answers?
            </Typography>
            <Typography variant="h6" textTransform={'uppercase'}>
                LIFE DOESN'T HAVE TO BE THIS HARD
            </Typography>
            <Box display={'flex'} textAlign={'left'} justifyContent={'center'}>
                <Typography variant="body1" py={5} px={2} maxWidth={'1024px'}>
                    After many years of struggling myself, I know just how
                    frustrating life can be. Maybe you're drifting through life
                    and have no idea what you want. Maybe you know what you
                    want, but you haven't been able to figure out how to get it.
                    Or perhaps you've tried, really, really hard, but you can't
                    seem to make progress.
                    <br />
                    <br />
                    Meanwhile, the days just keep going by and you keep drifting
                    along. You know you're not where you had hoped you'd be.
                    Life hasn't turned out how you expected. You're sick and
                    tired of feeling sick and tired.
                    <br />
                    <br />
                    It doesn't have to be that way! The reason life is hard
                    isn't because you're a failure. It's not because you don't
                    have what it takes or because you haven't tried hard enough.
                    It's simply because you don't have the right tools and
                    support you need.
                </Typography>
            </Box>
        </Box>
    );
};

export default Overwhelmed;

import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material';
import BusinessTopIntro from './BusinessServices/BusinessTopIntro';
import BusinessOfferedServices from './BusinessServices/BusinessOfferedServices';
import BusinessExhuasted from './BusinessServices/BusinessExhuasted';
import BusinessImagine from './BusinessServices/BusinessImagine';


const Services = () => {
    const theme = useTheme();
    theme.typography.h2 = {
        fontSize: '3rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <BusinessTopIntro />
            <BusinessExhuasted />
            <BusinessOfferedServices />
            <BusinessImagine />
        </ThemeProvider>
    );
};

export default Services;

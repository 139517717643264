// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import About from './components/About';
import Services from './components/Services';
import BusinessServices from './components/BusinessServices';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { usePageTracking } from './utils/Analytics';
// import { useMediaQuery } from '@mui/material';

const RoutesWithTracking = () => {
    usePageTracking();

    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/businessservices" element={<BusinessServices />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<LandingPage />} />
        </Routes>
    );
};

const App = () => {
    //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = createTheme({
        palette: {
            //mode: prefersDarkMode ? 'dark' : 'light',
            primary: {
                main: '#fff',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Header />
                <RoutesWithTracking />
                <Footer />
            </Router>
        </ThemeProvider>
    );
};

export default App;

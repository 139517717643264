import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    AppBar,
    Toolbar,
    Button,
    Box,
    IconButton,
    Drawer,
    useMediaQuery,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Link,
    Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ScheduleButton from './ScheduleButton';

const menuItems = [
    { text: 'Home', href: '/' },
    { text: 'Life Coaching', href: '/services' },
    { text: 'Leadership Coaching', href: '/businessservices' },
    { text: 'Testimonials', href: '/testimonials' },
    { text: 'About', href: '/about' },
    { text: 'Contact', href: '/contact' },
];

interface IDrawerMenu {
    onClose: () => void;
    setDrawerOpen: (drawerOpen: boolean) => void;
    drawerOpen: boolean;
}
const DrawerMenu = ({ onClose, setDrawerOpen, drawerOpen }: IDrawerMenu) => {
    return (
        <Box>
            <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={onClose}
                    onKeyDown={onClose}
                >
                    <List>
                        {menuItems.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton href={item.href}>
                                    {/* <ListItemIcon>
                              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                          </ListItemIcon> */}
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

const CoachDetails = ({ theme }) => {
    return (
        <Box m={1}>
            <Link
                href="/"
                underline="none"
                sx={{ color: theme.palette.common.black }}
            >
                <img
                    src="/images/header_logo.png"
                    alt="Nick Schader - Life Coach"
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                        maxHeight: '45px',
                        padding: 0,
                        margin: 0,
                    }}
                />
            </Link>
        </Box>
    );
};

const CoachInfo = ({ theme, isMobile }) => {
    return (
        <Box>
            {isMobile ? (
                <Grid
                    container
                    justifyContent="right"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={6}>
                        <CoachDetails theme={theme} />
                    </Grid>
                    <Grid item xs={6}>
                        <ScheduleButton />
                    </Grid>
                </Grid>
            ) : (
                <Box ml={3} mr={6}>
                    <CoachDetails theme={theme} />
                    {isMobile ? <ScheduleButton /> : null}
                </Box>
            )}
        </Box>
    );
};

const Header = () => {
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppBar>
            <Toolbar>
                {isMobile ? (
                    <DrawerMenu
                        onClose={() => setDrawerOpen(false)}
                        setDrawerOpen={setDrawerOpen}
                        drawerOpen={drawerOpen}
                    />
                ) : null}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <CoachInfo theme={theme} isMobile={isMobile} />
                    {isMobile ? null : (
                        <Box
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                            mt={1.5}
                        >
                            {menuItems.map((item, index) => (
                                <Button
                                    key={index}
                                    color="inherit"
                                    href={item.href}
                                >
                                    {item.text}
                                </Button>
                            ))}
                            <ScheduleButton
                                sx={{
                                    marginLeft: '50px',
                                    backgroundImage:
                                        'radial-gradient(at center center, #475B70 0%, #253342 100%)',
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

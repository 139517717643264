import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ScheduleButton from '../ScheduleButton';

const HowDoesItWork = () => {
    const details = [
        [
            'BOOK A FREE DISCOVERY CALL',
            'Click the button below to find out how I can help',
            <CalendarMonthOutlinedIcon
                sx={{ fontSize: '7rem', color: '#c5a774' }}
            />,
        ],
        [
            'DECIDE WHAT YOU WANT',
            'Create a vision for the life you want',
            <MapOutlinedIcon sx={{ fontSize: '7rem', color: '#c5a774' }} />,
        ],
        [
            'BEGIN YOUR JOURNEY',
            'Build the life you want with the support you need',
            <TaskAltOutlinedIcon sx={{ fontSize: '7rem', color: '#c5a774' }} />,
        ],
    ];

    return (
        <Box
            py={'3rem'}
            textAlign={'center'}
            sx={{
                backgroundColor: '#f2ece0',
                backgroundImage: 'url(/images/bgdots.png)',
            }}
        >
            <Typography variant="h2">How Does It Work?</Typography>
            <Typography variant="h6" textTransform={'uppercase'}>
                THREE STEPS TO START BUILDING THE LIFE YOU WANT
            </Typography>

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
                py={'3rem'}
                px={2}
            >
                {details.map((d, index) => (
                    <Grid key={index} item xs={12} md={4}>
                        {d[2]}
                        <Typography variant="h5" textTransform={'uppercase'}>
                            {d[0]}
                        </Typography>
                        <Typography variant="h6">{d[1]}</Typography>
                    </Grid>
                ))}
            </Grid>
            <ScheduleButton />
        </Box>
    );
};

export default HowDoesItWork;

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const gaCode = 'G-V7R4GW21LT';

export enum EventCategory {
    BookACallBtn = 'Book_A_Call_Button',
    ContactForm = 'Contact_Form',
}

export function sendPageView(path: string) {
    ReactGA.initialize(gaCode);
    ReactGA.send({
        hitType: 'pageview',
        page: path ?? window.location.pathname + window.location.search,
    });
}

export function sendEvent(
    category: EventCategory,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport: 'beacon' | 'xhr' | 'image' = 'xhr'
) {
    if (!window.location.href.includes('localhost')) {
        ReactGA.initialize(gaCode);
        ReactGA.event({
            category,
            action,
            label, // optional
            value, // optional, must be a number
            nonInteraction, // optional, true/false
            transport, // optional, beacon/xhr/image
        });
    } else {
        console.log('sendEvent:', {
            category,
            action,
            label, // optional
            value, // optional, must be a number
            nonInteraction, // optional, true/false
            transport, // optional, beacon/xhr/image
        });
    }
}

export const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    const [isLocalhost, setIsLocalhost] = useState(false);

    useEffect(() => {
        if (
            window.location.href.includes('localhost') ||
            window.location.href.includes('dev-renewedmind.coach.s3')
        ) {
            setIsLocalhost(true);
            console.log('initialize analytics');
        } else {
            ReactGA.initialize(gaCode);
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            const page = location.pathname + location.search;
            const hitType = 'pageview';
            if (isLocalhost) {
                console.log(hitType, page);
            }

            ReactGA.send({
                hitType,
                page,
            });
        }
    }, [initialized, location, isLocalhost]);
};

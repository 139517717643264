import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ScheduleButton from './ScheduleButton';

const Testimonials = () => {
    const testimonials = [
        {
            name: 'James W. - California',
            feedback:
                'Working with Nick helped give me focus and set goals both at work and at home. Nick is dedicated to helping you succeed and is willing to put in the time and effort to guide you through the process.',
        },
        {
            name: 'David A. - California',
            feedback:
                "I started meeting with Nick after stepping into a new career. I didn't know if I could cut it in my industry, but Nick laid out a solid plan for me to keep growing. His coaching methods are great for both professional and personal goals, as he believes balance is the key to a happy and successful life. I'm now thriving in my position and I have Nick's guidance to thank for that.",
        },
        {
            name: 'José F. - Florida',
            feedback:
                'Nick has an incredible knack for making me feel completely at home, laying the groundwork for what would be an incredible journey of personal and professional development. His dedication to my growth was clear—he put together a personalized plan to help me evolve. He has an eye for seeing potential and knows exactly how to help us reach it, pushing me to elevate my skills further.',
        },
        {
            name: 'Mark H. - California',
            feedback:
                'Nick was committed to my growth inside and outside the workplace. He understands the balance and work that is required to find fulfillment and success in all facets of life.',
        },
    ];

    return (
        <Box pt={12}>
            <Typography variant="h4" gutterBottom textAlign={'center'}>
                True Stories from People Just Like You
            </Typography>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    maxWidth={'800px'}
                    pb={5}
                >
                    What you're about to read are true stories from people I've
                    worked with who, just like you, started with the dream to
                    take their life to the next level. With the right guidance
                    and support, those dreams have become their reality, and I
                    couldn't be more excited to share their experiences with you
                    below.
                </Typography>
            </Box>

            <Box
                textAlign={'center'}
                pb={5}
                pt={5}
                sx={{
                    backgroundColor: '#f2ece0',
                    backgroundImage: 'url(/images/bgdots.png)',
                }}
                display={'flex'}
                justifyContent="center"
            >
                <Box maxWidth={'900px'} alignItems={'center'}>
                    <Grid container spacing={2} alignItems="center">
                        {testimonials.map((testimonial, index) => (
                            <Grid
                                key={index}
                                item
                                md={12}
                                display={'flex'}
                                justifyContent={
                                    index % 2 === 0 ? 'flex-start' : 'flex-end'
                                }
                                textAlign={'left'}
                            >
                                <Card
                                    sx={{
                                        m: 1,
                                        maxWidth: '600px',
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            variant="subtitle1"
                                            maxWidth={'540px'}
                                        >
                                            {testimonial.feedback}
                                        </Typography>
                                        <Typography variant="h6">
                                            {testimonial.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

            <Typography variant="h4" gutterBottom textAlign={'center'} pt={5}>
                Ready to Add Your Own Story?
            </Typography>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography
                    variant="body1"
                    maxWidth={'1080px'}
                    textAlign={'center'}
                >
                    You've just read about the incredible results of some of my
                    past clients, people who shared your same challenges. Their
                    journey from where you are to where you want to be started
                    with a single step, and for a short time, I'm excited to
                    offer that same opportunity to you. If you're ready to get
                    started, click the button below now to book your free
                    Discovery Call today.
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={5}>
                <ScheduleButton />
            </Box>
        </Box>
    );
};

export default Testimonials;

import React from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material';

const About = () => {
    const theme = useTheme();

    return (
        <Box>
            <Box 
                sx={{
                    backgroundImage:
                        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/images/desk.jpeg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: theme.palette.common.white,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                ></Box>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    mt={'8rem'}
                >
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" textAlign={'center'}>
                            Nick Schader
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" textAlign={'center'} sx={{}}>
                    LIFE COACH FOR MEN
                </Typography>
            </Box>
            <Box
                sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    py: 2,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    width: '100%',
                    backgroundImage:
                        'radial-gradient(at center center, #475B70 0%, #253342 100%)',
                    color: theme.palette.common.white,
                }}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">VISION</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">ACTION PLAN</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">MINDSET</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                p={3}
                pt={12}
                display={'flex'}
                justifyContent={'center'}
                sx={{
                    backgroundColor: '#f2ece0',
                    backgroundImage: 'url(/images/bgdots.png)',
                }}
            >
                <Box>
                    <Typography variant="body1" maxWidth={'1024px'}>
                        Nick is a husband, father, and life coach. He's wildly in
                        love with his wife of over 25 years. Together they have 7
                        children and 1 grandchild. They've built a beautiful life
                        together, but it hasn't always been that way.
                        <br />
                        <br />
                        Having grown up in a turbulent home and being kicked out at
                        18 made for a rough start, but it also gave him a strong
                        desire for something better. He and his wife started
                        dating at 17 and had their first child at 20. They didn't
                        know how to build the life they desired, but they knew they
                        wanted to create something better for their children.
                        <br />
                        <br />
                        Fast forward a few years and he found himself struggling in
                        most areas of life. He had hoped for something better, but
                        it certainly wasn't headed that way. There just wasn't
                        enough time, energy, or money to go around. He felt stuck
                        and didn't know how to move forward. He decided it was time
                        for something to change.
                        <br />
                        <br />
                        He read and watch everything he could get his hands on that
                        would provide the answers. After a few more years without
                        noticeable progress, he realized that it takes more than
                        knowledge to make a change. It requires shedding limiting 
                        beliefs and it requires action.
                        <br />
                        <br />
                        With the help of a coach, he began the process to become the
                        husband, father, and man that he wanted his sons to become
                        and his daughters to marry. He began to make progress in all
                        areas of life.
                        <br />
                        <br />
                        For the past several years, he's led a large team at a
                        software development company. During this time, he found his
                        passion for coaching others to become better versions of
                        themselves at work and in life.
                        <br />
                        <br />
                        He's now coaching full-time and would love to help you build
                        the life you want.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default About;

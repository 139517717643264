import React from 'react';
import { Box, Typography } from '@mui/material';

const BusinessExhuasted = () => {
    return (
        <Box pt={'4rem'} textAlign={'center'} alignItems={'center'}>
            <Typography variant="h4" fontFamily={"'Raleway',sans-serif;"}>
                Exhausted & Out of Answers?
            </Typography>
            <Typography variant="h6" textTransform={'uppercase'}>
                LEADERSHIP DOESN'T HAVE TO BE THIS HARD
            </Typography>
            <Box
                display={'flex'}
                textAlign={'left'}
                justifyContent={'center'}
            >
                <Typography variant="body1" py={5} px={2} maxWidth={'1024px'}>
                    After many years of struggling as a leader myself, I 
                    know just how challenging leadership can be. Maybe 
                    you're struggling with underperforming team members 
                    and you don't know how to address it effectively. 
                    Maybe you need to hire but you're afraid of bringing 
                    on the wrong person. Or perhaps you just have too much 
                    on your plate and don't know how to get it all done.
                    <br />
                    <br />
                    Meanwhile, the days just keep going by and the to do list
                    keeps getting longer. You know your team is not where you 
                    had hoped they would be. Leadership hasn't turned out how 
                    you expected. You're ready to throw in the towel and go
                    back to being an individual contributor.
                    <br />
                    <br />
                    It doesn't have to be that way! The reason leadership is 
                    hard isn't because you're a failure. It's not because you 
                    don't have what it takes or because you haven't tried hard 
                    enough. It's simply because you don't have the right tools 
                    and support you need. And how would you? You probably haven't
                    had great examples to follow.
                </Typography>
            </Box>
        </Box>
    );
};

export default BusinessExhuasted;

import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import SentimentVeryDisatisfied from '@mui/icons-material/SentimentVeryDissatisfied';

const CommonObstacles = () => {
    const details = [
        [
            'LOST?',
            'Do you feel like you have no purpose or direction? Are you overwhelmed by all the options?',
        ],
        [
            'SCARED?',
            'Are you afraid to step out of your comfort zone? Are you worried you might fail?',
        ],
        [
            'STUCK?',
            "Have you tried to make the changes but can't seem to make progress?",
        ],
    ];

    return (
        <Box
            pt={'4rem'}
            textAlign={'center'}
            sx={{
                backgroundColor: '#f2ece0',
                backgroundImage: 'url(/images/bgdots.png)',
            }}
        >
            <Typography variant="h2">
                The Most Common Obstacles to Living the Life You Want
            </Typography>
            <Typography variant="h6" textTransform={'uppercase'}>
                Can you relate to these struggles?
            </Typography>

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
                py={'3rem'}
                px={1}
            >
                {details.map((d, index) => (
                    <Grid key={index} item xs={12} md={4}>
                        <SentimentVeryDisatisfied
                            sx={{ fontSize: '7rem', color: '#c5a774' }}
                        />
                        <Typography variant="h5" textTransform={'uppercase'}>
                            {d[0]}
                        </Typography>
                        <Typography variant="h6">{d[1]}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CommonObstacles;
